'use client'
import axiosInstance from "../../api/axiosInstance";
import Nav from "../../components/Nav";
import Hero from "../../components/ProjectsComp/cedarmount/Hero";
import MoreWhite from "../../assets/images/Projects/iceking/MoreWhite.png";
import Sticker from "../../assets/images/desktopView/Frame.png";
import { Link } from "react-router-dom";
import ContactUs from "../../components/ContactUs";
import ShowPics from "../../components/ProjectsComp/cedarmount/ShowPics";
import { ProjectType } from "../../../types";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface Params {
  params: {
    id: string;
  };
}

interface ProjectProps {
  data: ProjectType;
}

// Generate static paths for each project using the `id`
export async function generateStaticParams() {
    try {
    const response = await axiosInstance.get("api/projects");
    const data: ProjectType[] = response.data.data; // Assuming your data is nested inside `data.data`

    return data.map((project) => ({
      id: project.id.toString(), // Convert id to string if it's a number
    }));
  } catch (error) {console.log(error)}
}

// The page component that receives `params` as props
export default  function Project() {
  const { id } = useParams();
  const [data, setData] = useState<ProjectType | undefined>()
  // Fetch project data based on the `id`
  

  // Use state to manage navigation colors
  const [navColorLogo, setNavColorLogo] = useState<boolean>(false);
  const [navColor, setNavColor] = useState<string>("black");

  // Change navigation color on scroll
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.get(`api/projects/show/${id}`);
        setData(response.data.data);
      } catch (error) {console.log(error)}
    }
    getData()
    const section = document.querySelector(".ice-king");
    const handleScroll = () => {
      if (section) {
        const sectionTop = section.clientHeight;
        if (window.pageYOffset >= sectionTop - 1000) {
          setNavColorLogo(true);
          setNavColor("white");
        } else {
          setNavColorLogo(false);
          setNavColor("black");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Horizontal scroll for images
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleWheel = (evt: WheelEvent) => {
      evt.preventDefault();
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += evt.deltaY;
      }
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleWheel);

      return () => {
        scrollContainer.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  // Change the scroll bar color based on the project's color
  useEffect(() => {
    if (data?.color) {
      document.documentElement.style.setProperty("--scroll-color", `#${data.color}`);
    }
  }, [data?.color]);

  return (
    data? (
    <div className="ice-king">
      <Nav humColor={navColor} LogoWhite={true} />
      <div ref={scrollContainerRef} className="scroll-brown hidden md:flex h-screen min-w-[100vw] overflow-y-hidden">
        <div className="min-w-[100vw] min-h-[100vh]">
          <Hero 
            cover_image={data.cover_image} 
            title={data.title} 
            name={data.name}
            description={data.description ?? data.expanded_of_description ?? undefined}
            services={data.services.map(service => service.name).join(", ")} 
          />
        </div>
        <div className={`flex`} style={{ backgroundColor: `#${data.color}` }}>  
          {
          data.images.map((image, index) => {
            if (data && data.images && index !== data.images.length - 1) {
              return (
                <div key={`project-image-${index}`} className="min-w-[100vw] min-h-[100vh]">
                  <div className="bg-none w-full h-full flex items-center justify-center">
                    <img className="3xl:scale-125 w-1/2" src={`https://api-laith.alzero1store.com/${image.image}`} alt="" />
                  </div>
                </div>
              );
            }
            return (
              <div key={`project-image-${index}`} className="min-w-[100vw] min-h-[100vh]">
                <div className="bg-none w-full h-full flex items-center justify-center relative">
                  <img className="-translate-x-36 w-1/2 2xl:-translate-x-[300px]" src={`https://api-laith.alzero1store.com/${image.image}`} width={400} height={400} alt="" />
                  <Link to="/discover">
                    <img className="w-1/3 scale-[0.8] absolute right-8 top-1/2 -translate-y-1/2" src={MoreWhite} alt="" />
                  </Link>
                  <img className="absolute -right-24 z-20 scale-75 translate-y-32" src={Sticker} alt="" />
                </div>
              </div>
            );
          })}
        </div>
        <div className="min-w-[100vw] min-h-[100vh]">
          <ContactUs />
        </div>
      </div>
      <div className="block md:hidden overflow-x-hidden">
        <Hero 
          cover_image={data.cover_image} 
          title={data.title} 
          name={data.name}
          description={data.description ?? data.expanded_of_description ?? undefined}
          services={data.services.map(service => service.name).join(", ")} 
        />
        <ShowPics images={data.images} color={data.color} />
        <ContactUs />
      </div>
    </div>   ) : (<></>)
  );

}
