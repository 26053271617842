'use client'
import { useEffect, useRef, useState } from 'react';
import Nav from './components/Nav';
import FirstSection from './components/Home/FirstSection';
import SecondSection from './components/Home/SecondSection';
import Project from './components/Home/Project';
import ThirdSection from './components/Home/ThirdSection';
import ContactUs from './components/ContactUs';
import axiosInstance from './api/axiosInstance';
import PinkYellow from './assets/images/desktopView/pinkYellow.png'
import Styles from "./Home.css"
export interface ServiceType {
  id: number;
  name: string;
  is_published: number;
}

export interface ProjectType {
  id: number;
  presentation_image: string;
  title: string;
  services: ServiceType[];
  color?: string;
  projectDescription?: string;
  cover_image ?: string;
}

export default function Home() {
  const [rotate, setRotate] = useState<number>(0);
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleWheel = (evt: WheelEvent) => {
      evt.preventDefault();
      if (scrollContainerRef.current) {
        if (rotate >= 0) {
          setRotate((prevRotate) => prevRotate + 10 * Math.sign(evt.deltaY));
        }
        scrollContainerRef.current.scrollLeft += evt.deltaY;
      }
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheel);

      return () => {
        scrollContainer.removeEventListener('wheel', handleWheel);
      };
    }
  }, [rotate]);

  const getProjects = async () => {
    try {
      const res = await axiosInstance.get('api/projects/published');
      if (res.status === 200) {
        const formattedProjects = res.data.data.map((project: ProjectType) => ({
          ...project,
          projectDescription: project.services.map((service) => service.name).join(', '),
        }));
        setProjects(formattedProjects);
      }
    } catch (error) {
      console.error('Failed to fetch projects:', error);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <main className="overflow-x-hidden">
      <Nav LogoWhite={true} humColor="white" />
      <div
        ref={scrollContainerRef}
        className="scroll-yellow hidden md:flex h-screen min-w-[10vw] overflow-y-hidden"
      >
        <div className="min-w-[100vw] min-h-[100vh]">
          <FirstSection rotate={rotate} setRotate={setRotate} />
        </div>
        <div className="min-w-[100vw] min-h-[100vh]">
          <SecondSection />
        </div>
        {projects.map((project, index) => {
  if (index < 4) {
    return (
      <div key={project.id} className="min-w-[100vw] min-h-[100vh] relative">
        <Project
          id={project.id}
          imageSrc={project.presentation_image}
          ProjectName={project.title}
          ProjectDescription={project.projectDescription ?? ''}
          color={project.color || '#000000'}
          more={false} // Assuming no 'more' parameter from API
          routingLink={`/projects/${project.id}`}
        />
        {index === 0 && (
          <img
            className={`absolute w-[500px]  top-1/2 -translate-y-1/2 md:-left-[200px] lg:-left-[250px] md:w-[400px] lg:w-[500px] z-20`}
            src={PinkYellow}
            alt=""
            
          />
        )}
      </div>
    );
  }
  return null;
})}
        <div className="min-w-[100vw] min-h-[100vh]">
          <ThirdSection />
        </div>
        <div className="min-w-[100vw] min-h-[100vh] overflow-hidden">
          <ContactUs />
        </div>
      </div>
      <div className="block md:hidden overflow-x-hidden">
        <div className="block md:hidden h-screen">
          <FirstSection />
        </div>
        <div className="relative">
          <SecondSection />
          <img
            className="absolute top-1/2  w-[500px] -translate-y-1/2 md:-left-[200px] lg:-left-[250px] md:w-[400px] lg:w-[500px] z-20"
            src={PinkYellow}
            alt=""
      
          />
        </div>
        <div className="Home-third-mob overflow-hidden pt-[250px]">
          {projects.map((project) => (
            <Project
              key={project.id}
              id={project.id}
              imageSrc={project.presentation_image}
              ProjectName={project.title}
              ProjectDescription={project.projectDescription ?? ''}
              color={project.color || '#000000'}
              more={false} // Assuming no 'more' parameter from API
              routingLink={`/projects/${project.id}`}
            />
          ))}
          <ThirdSection />
          <ContactUs autoBg={true} />
        </div>
      </div>
    </main>
  );
}

