import Nav from "../../components/Nav";
import styles from "./about.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Lets from "../../assets/images/desktopView/LetsGet.png";
import sunglasses from "./../../assets/images/about/sunglasses.svg";
import lineDance from "./../../assets/images/about/lineDance.png";
import lineExplore from "./../../assets/images/about/lineExplore.png";
import lineDefine from "./../../assets/images/about/lineDefine.png";
import lineCreate from "./../../assets/images/about/lineCreate.png";
import tringle from "./../../assets/images/about/tringle.png";
import smallStar from "./../../assets/images/about/smallStar.svg";
import bigStar from "./../../assets/images/about/bigStar.svg";
import lineDiscover from "./../../assets/images/about/lineDiscover.png";

import { Link } from "react-router-dom";
import ContactUs from "../../components/ContactUs";

const AboutUs = () => {
  return (
    <div className="overflow-x-hidden">
      <Nav humColor="white" LogoWhite={true} />
      <div
        className={`${styles.about} bg-background text-white pt-[100px] text-center`}
      >
        <div className={`  w-11/12 mx-auto`}>
          <div className="relative">
            <h1
              className={`${styles.aboutHeading} ${styles.darkline} text-yellow text-6xl md:text-7xl mb-[40px]`}
            >
              Not your <br />
              typical agency
            </h1>
            <img
              src={Lets}
              alt=""
              className="absolute w-[100px] h-[66px] md:w-[200px] md:h-[150px] -top-10 right-[25%] md:right-[10%] lg:right-[20%] md:-top-16 object-contain"
            />
          </div>
          <div className={`${styles.aboutDesc} ${styles.lato}`}>
            <p>
              Shaped by the concept that no one size fits all, Laith Abbas
              Studio has found its place as a fast-paced and flexible studio
              that spans multiple branding, marketing, and production areas.
              Since its inception in 2020, we've grown on innovative ideas and
              hard work.
            </p>
            <br />
            <p>
              Together with new talent, freelancers, and partner agencies, we
              now deliver solutions for our clients over the world.
            </p>
            <br />
            <p>
              We like to be different, in the same way that every mission is
              different. Every project is an opportunity to create awesome
              results that help you take the next step to the top. We see
              ourselves as the opposite of poopy-headed (yes you read that
              right) and our focus is on smooth collaboration, winning
              solutions, and the power of a well-timed joke.
            </p>
          </div>
          <div className="relative md:flex justify-between items-start md:mb-[50px]">
            <h2
              className={`${styles.subHeading} text-yellow text-start text-wrap md:w-[70%]`}
            >
              <div className={`text-pink`}>Assembled or in parts? </div>
              whichever suits you best of course
            </h2>
            <img
              src={sunglasses}
              alt=""
              className="absolute top-28 right-0 md:static flex-grow md:object-contain md:m-10"
            />
          </div>
          <div className="lg:flex justify-between">
            <div className={`pb-[70px] lg:w-[40%]`}>
              <h1 className={`${styles.sectionHeading} ${styles.darkline}`}>
                Trademark
              </h1>
              <p className={`lato ${styles.sectiondesc} mb-8`}>
                By reviewing your brand's what, how & why, we can shape the
                foundation for a unified brand experience. From the name to the
                visual identity, we'll make sure everything tells the story you
                want to tell, shows personality & dances with your target
                audience. We tailor the brand journey to your needs & get you
                safely to port with a winning brand in your suitcase.
              </p>
              <ul
                className={`${styles.sectionList} lato text-pink text-start`}
              >
                <li>- Naming</li>
                <li>- Workshop & Analysis</li>
                <li>- Brand strategy</li>
                <li>- Positioning</li>
                <li>- Communication strategy</li>
                <li>- Visual identity</li>
              </ul>
            </div>
            <div className={`pb-[70px] lg:w-[40%]`}>
              <h1 className={`${styles.sectionHeading} ${styles.darkline}`}>
                Content
              </h1>
              <p className={`lato ${styles.sectiondesc} mb-8`}>
                By reviewing your brand's what, how & why, we can shape the
                foundation for a unified brand experience. From the name to the
                visual identity, we'll make sure everything tells the story you
                want to tell, shows personality & dances with your target
                audience. We tailor the brand journey to your needs & get you
                safely to port with a winning brand in your suitcase.
              </p>
              <ul
                className={`${styles.sectionList} lato text-pink text-start`}
              >
                

                <li>- Social Media Management</li>
                <li>- Graphic Designs</li>
                <li>- Illustration</li>
                <li>- Animation</li>
                <li>- 3D Visualization</li>
                <li>- Film & Photo</li>
                <li>- Campaign</li>
              </ul>
            </div>
          </div>

          <div
            className={`${styles.darkline} text-5xl scale-100 -translate-x-4 lg:scale-150 text-yellow my-[100px] flex flex-col items-center`}
          >
            How we dance
            <img src={lineDance} alt="" className="ms-40 -translate-y-6" />
          </div>

          <div className="lg:flex flex-wrap justify-between">
            <div className={`${styles.plan}`}>
              <h1
                className={`${styles.sectionHeading} ${styles.darkline} mb-8`}
              >
                Explore
              </h1>
              <p className={`lato ${styles.sectiondesc} mb-8`}>
                Initially, it's about understanding you and your business. What
                is your current situation and where are you in the market? What
                are your goals and visions? Depending on the scope of the
                project and the challenges faced, we will form a clear
                understanding of you, your brand, and your customers through
                workshops, research, interviews, and surveys.
              </p>
            </div>
            <img
              src={lineExplore}
              alt=""
              className={`${styles.arrow} w-[140px] h-[72]`}
            />
            <div className={`${styles.plan}  lg:mt-[150px]`}>
              <h1
                className={`${styles.sectionHeading} ${styles.darkline}  mb-8`}
              >
                Define
              </h1>
              <p className={`lato ${styles.sectiondesc} mb-8`}>
              Through our findings, we create insights and key points  based on the customer's reality. Unique characteristics,  skills, and challenges of the client or target group can  then guide us further in our work. We explore creative  concepts, visual traces, and technical possibilities that  become the basis for the further direction of the journey
              </p>
            </div>
            <img
              src={lineDefine}
              alt=""
              className={`${styles.arrow} w-[140px] h-[72] mb-[27px] lg:hidden`}
            />
            <div className={`${styles.plan}`}>
              <h1
                className={`${styles.sectionHeading} ${styles.darkline}  mb-8`}
              >
                Create
              </h1>
              <p className={`lato ${styles.sectiondesc} mb-8`}>
                Once we’ve built a foundation, we start with design and
                production. With a packed backpack of insights, concepts, and
                ideas, we design all the necessary elements into a sharp,
                functional, and sustainable solution.
              </p>
            </div>
            <div className="flex flex-col justify-between">
              <img
                src={lineDefine}
                alt=""
                className={` w-[140px] h-[72] mb-[27px] hidden lg:mb-0 lg:block self-start me-[20%]`}
              />
              <img
                src={lineCreate}
                alt=""
                className={` w-[140px] h-[72] mb-[27px] hidden lg:block lg:mb-[400px]`}
              />
            </div>

            <img
              src={lineCreate}
              alt=""
              className={`${styles.arrow} w-[140px] h-[72] mb-[27px] lg:hidden`}
            />
            <div className={`${styles.plan} pb-[126px] lg:mt-[250px]`}>
              <h1
                className={`${styles.sectionHeading} ${styles.darkline} mb-8`}
              >
                Activate
              </h1>
              <p className={`lato ${styles.sectiondesc} mb-8`}>
                When your product, website or visual identity is polished down
                to the last detail, we make sure the result is visible to the
                world. We help you launch, create content and market. With
                ongoing feedback throughout the process, we make sure we achieve
                the results and goals we set.
              </p>
            </div>
          </div>

          <div className="relative pb-8 md:pb-[175px]">
            <Link
              to={"/discover"}
              className="flex items-center justify-center ms-[10%] lg:ms-0"
            >
              <img
                src={bigStar}
                alt=""
                className="self-start lg:w-[60px] lg:h-[60px]"
              />
              <div className="flex flex-col items-center justify-center">
                <h2 className={`${styles.discoverLink} uppercase`}>
                  discover <br /> our work
                </h2>
              </div>
              <img
                src={smallStar}
                alt=""
                className="self-end mb-5 ms-3 lg:w-[30px] lg:h-[30px]"
              />
              <img
                src={tringle}
                alt=""
                className="w-[90px] h-[90px] relative lg:static lg:w-[210px] lg:h-[230px] -top-10 right-10 object-contain"
              />
            </Link>
            <img
              src={lineDiscover}
              alt=""
              className="mx-auto w-[20%] lg:w-[40%] "
            />
          </div>
          <ContactUs autoBg={true} />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
