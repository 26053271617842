import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import AboutUs from './pages/about/page';
import Discover from './pages/discover/page';
import Project from './pages/projects/page';
import ContactUs from './components/ContactUs';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/about",
    element: <AboutUs/>,
  },
  {
    path: "/discover",
    element: <Discover/>,
  },
  {
    path:"/contact-us",
    element: <ContactUs/>
  },
  {
    path: "/projects/:id",
    element: <Project/>
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);


