import styles from "./discover.module.css";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  description: string;
  img: string;
  link?:string;
}

const DiscoverCard = ({ title, description, img , link}: Props) => {
  return (
    <div
      className={`lato ${styles.discoverCard} mb-12 mx-auto md:mx-0 w-[95%]`}
    >
      <Link to={`${link}`}>
      <div className={`${styles.discoverCardImage}`}>
        <img
          src={img}
          alt=""
          className={` mb-6 w-full h-[334px] md:h-auto object-cover`}
          />
      </div>
      <h2
        className={`${styles.discoverCardHeading} uppercase text-yellow mb-1`}
        >
        {title}
      </h2>
      <p className={`${styles.discoverCardDescription} text-pink mt-0`}>
        {description}
      </p>
        </Link>
    </div>
  );
};

export default DiscoverCard;
