import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { Spinner } from 'reactstrap';

// Define an interface for the service objects
interface Service {
  name: string;
  // Add any other properties of the service object here
}

export default function SecondSection() {
  const [services, setServices] = useState<Service[]>([]); // Specify the type as Service[]
  const [video, setVideo] = useState<string[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loadVedio , setLoadVedio] = useState(false)

  const getVideo = async () => {
    try {
      const res = await axiosInstance.get('api/video');
      if(res.status === 200) {
        setVideo(Object.values(res.data.data));
        setLoadVedio(true)
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getServices = async () => {
    try {
      const res = await axiosInstance.get('api/projects/services/published');
      const publishedServices = res.data['Published_Services'] as Service[]; // Specify the type as Service[]
      let repeatedServices = publishedServices;
      const staticServicesCount = 25;
      if (publishedServices.length < staticServicesCount) {
        repeatedServices = Array.from({ length: staticServicesCount }, (_, index) => publishedServices[index % publishedServices.length]);
      } else if (publishedServices.length > staticServicesCount) {
        repeatedServices = publishedServices.slice(0, staticServicesCount);
      }

      setServices(repeatedServices);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getVideo();
    getServices();
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className='second-section-home w-full h-[80vh] md:h-full relative overflow-visible z-20'>
      <div className='text-[#262626] text-[45px] Bebas scale-90 -translate-x-12 -translate-y-24 lg:scale-100 lg:translate-x-0'>
        <div className='marquee-text-1 text-start ps-4 w-1/3 hidden md:block' data-motion-id="animation-marqe-1">
          {services.map((service, index) => (
            <span key={index} className='block xl:text-[48px] 2xl:text-[50px] 3xl:text-[75px]'>{service.name}</span>
          ))}
        </div>
        <div className='marquee-text-2 text-start ps-4 hidden md:block' data-motion-id="animation-marqe-2">
          {services.map((service, index) => (
            <span key={index} className='block xl:text-[48px] 2xl:text-[50px] 3xl:text-[75px]'>{service.name}</span>
          ))}
        </div>
        <div className="ticker-wrap block md:hidden">
          <div className="ticker" data-motion-id="animation-marqe-3">
            {services.map((service, index) => (
              <div key={index} className="ticker__item">{service.name}</div>
            ))}
          </div>
        </div>
      </div>
      <div className='w-11/12 md:w-1/2 flex justify-center absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 md:left-auto md:translate-x-0 md:right-36 lg:right-[220px] xl:text-[48px] 2xl:text-[50px] 3xl:text-[75px]'>

        {(video[0] && loadVedio) ? (
          <video
            className=''
            src={`https://api-laith.alzero1store.com/${video[1]}`}
            ref={videoRef}
            onClick={handlePlayPause}
          />
        ) : (<Spinner
          data-motion-id ="spinner"
          className='scale-[3]'
          color="dark"
          type="grow"
        >
          Loading...
        </Spinner>)}
        {(!isPlaying && loadVedio) && (
          <div
            className="absolute inset-0 flex items-center justify-center cursor-pointer"
            onClick={handlePlayPause}
          >
            <svg className='text-white scale-150 xl:scale-90' xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/></g></svg>
          </div>
        )}
      </div>
    </div>
  );
}
