import styles from "./aboutOverview.module.css";
import emoji from "./../../assets/images/Shapes/emoji.png";
import ReadMore from '../../assets/images/desktopView/Group 4.png'
import { Link } from "react-router-dom";
import b1 from '../../assets/images/desktopView/Brands/Group 146.png'
import b2 from '../../assets/images/desktopView/Brands/Group 147.png'
import b3 from '../../assets/images/desktopView/Brands/Group 148.png'
import b4 from '../../assets/images/desktopView/Brands/Group 149.png'
import b5 from '../../assets/images/desktopView/Brands/Group 150.png'
import b6 from '../../assets/images/desktopView/Brands/Group 151.png'
// import b6 from '../../../public/images/desktopView/Brands/Group 154.png'

const AboutOverview = () => {
  const brands = [
    b1,
    b2,
    b3,
    b4,
    b5,
    // b6,
    b1,
    b2,
    b3,
    b4,
    b5,
    // b6,
  ];
  const brandsTow = [
    b1,
    // b6,
    b4,
    b2,
    // b6,
    b5,
    b3,
    b1,
    b2,
    b5,
    b4,
    b3,
  ];
  return (
    <div
      className={`${styles.OverviewMain} lg:flex lg:flex-row items-center justify-center lg:h-screen w-screen overflow-y-hidden`}
    >
      <div className='w-11/12 md:w-3/4 scale-90 m-auto'>
            <h2 className='text-third-section mt-20 md:mt-0 Bebas text-[#F6174E] leading-[65px] md:leading-[70px] lg:leading-[100px] text-center md:text-left w-[110%] -ms-[5%] md:ms-0 md:w-full md:whitespace-nowrap'>WE HAVE A PASSION FOR CONTROLLING <br />CONSUMER'S MIND THROUGH <span className='text-[#C7DA02] block md:inline'> A DESIGN</span></h2>
            <div className='flex items-center'>
                <div className='flex flex-col items-center relative w-full sm:w-full text-left md:text-center'>
                    <p className='text-[14px] sm:text-[16px] 3xl:text-[23px] text-center Lato font-[400] mb-6 text-[#fff] mt-6 md:mt-3'>The law of the jungle is tough and says that only the strongest survive. Before you can effectively communicate your brand, you must frst build muscle by understanding your customers and competitors. We'll help you get to the heart of your business and together we'll navigate through research, strategy, design choices, and digital opportunities. A creative adventure. We like a challenge, but we don't overcomplicate it and make sure the journey is as smooth as possible.</p>
                    <p className='text-[14px] sm:text-[16px] 3xl:text-[23px] text-center Lato font-[400] text-[#fff]'>Brand builder, Design studio, Accomplice, Dance partner. What you call us doesn't matter, what matters is that we steer your brand in the right direction. The frst step is to become friends, so come over for a Coke cola and we'll be happy to tell you more. Coffee works too, of course.</p>
                    <Link className='w-full flex justify-center items-center' to={'/about'} ><img className='scale-75 mt-16 md:mt-0 mb-52 md:mb-0 md:absolute -bottom-32 md:scale-90' src={ReadMore} alt='' /></Link>
                </div>
            </div>
        </div>
      <div className='lg:flex flex-col lg:gap-[40px] scale-[0.6] lg:scale-90 translate-x-[400px] lg:-translate-x-4 absolute rotate-90 lg:static lg:rotate-0 -translate-y-[1900px] lg:-translate-y-[10px] gap-3'>
        <div className='marquee-text-3 text-start ps-4 w-fit flex flex-col gap-[30px]'>
                <img className='-rotate-90 lg:rotate-0' src={b1} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b2} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b3} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b4} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b5} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b6} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b1} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b2} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b3} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b4} alt='' />
            </div>
        <div className='marquee-text-4 text-start ps-4 w-fit flex flex-col gap-[30px]'>
4               <img className='-rotate-90 lg:rotate-0' src={b1} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b5} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b6} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b1} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b2} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b3} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b4} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b5} alt='' />
                <img className='-rotate-90 lg:rotate-0' src={b6} alt='' />
                <img className='-rotate-90 lg:rotate-0 opacity-0 lg:opacity-100' src={b2} alt='' />
            </div>
        </div>
        <div className='hidden lg:flex flex-col rotate-180 scale-90 -translate-x-8 gap-[40px]'>
        <div className='marquee-text-3 text-start ps-4 w-fit flex flex-col gap-[30px]'>
4               <img className="rotate-180" src={b1} alt='' />
                <img className="rotate-180" src={b2} alt='' />
                <img className="rotate-180" src={b3} alt='' />
                <img className="rotate-180" src={b4} alt='' />
                <img className="rotate-180" src={b5} alt='' />
                <img className="rotate-180" src={b6} alt='' />
                <img className="rotate-180" src={b1} alt='' />
                <img className="rotate-180" src={b2} alt='' />
                <img className="rotate-180" src={b3} alt='' />
                <img className="rotate-180" src={b4} alt='' />
            </div>
        <div className='marquee-text-4 text-start ps-4 w-fit flex flex-col gap-[30px]'>
4               <img className="rotate-180" src={b5} alt='' />
                <img className="rotate-180" src={b6} alt='' />
                <img className="rotate-180" src={b1} alt='' />
                <img className="rotate-180" src={b2} alt='' />
                <img className="rotate-180" src={b3} alt='' />
                <img className="rotate-180" src={b4} alt='' />
                <img className="rotate-180" src={b5} alt='' />
                <img className="rotate-180" src={b6} alt='' />
                <img className="rotate-180" src={b1} alt='' />
                <img className="rotate-180" src={b2} alt='' />
            </div>
        </div>
    </div>
  );
};

export default AboutOverview;
