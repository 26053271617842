import React from 'react'


interface ServiceType {
  id: number;
  name: string;
  is_published: number;
}


interface HeroType {
  id ?: number;
  service_id?: ServiceType;
  services?: string;
  name?: string;
  title?: string;
  description?: string;
  expanded_of_description?: string | null;
  cover_image?: string;
}

const Hero: React.FC<HeroType> = ({
  id,
  service_id,
  services,
  name,
  title,
  description,
  expanded_of_description,
  cover_image,
}) => {
  return (
    <div className='bg-white flex flex-col md:flex-row'>
      <div className='w-full md:w-fit h-[50vh] md:h-screen'>
        <img className=" w-full lg:w-fit h-full" src={`https://api-laith.alzero1store.com/${cover_image}`} width={400} height={400} alt='' />
      </div>
      <div className=' w-11/12 m-auto md:w-[350px] lg:w-1/3 h-[50vh] md:h-screen text-[#000] flex flex-col items-start justify-center md:ms-5 lg:ms-12 text-start relative md:px-4'>
        <h3 className='uppercase text-[18px] md:text-[23px] lg:text-[32px] Lato font-[400] leading-[35px]'>{title}</h3>
        <h4 className='text-[48px] md:text-[55px] lg:text-[90px] 3xl:text-[100px] Bebas font-[400] leading-[45px] md:leading-[45px] lg:leading-[80px]'>{name}</h4>
        <p className='text-[10px] md:text-[14px] lg:text-[16px] 3xl:text-[20px] Lato font-[400] mb-3 md:mb-6 mt-4 md:mt-6 lg:mt-12'>{description}</p>
        <span className=' absolute bottom-4 md:bottom-12 left-0 text-[10px] md:text-[12px] lg:text-[15px] 3xl:text-[17px] Lato font-[600] '>
          {services}
        </span>
      </div>
    </div>
  )
}

export default Hero;
