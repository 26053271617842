import React, { useEffect, useState } from 'react'
import axiosInstance from '../../api/axiosInstance'
import YellowSticker from '../../assets/images/desktopView/Frame.png'
import ReadMore from '../../assets/images/desktopView/Group 4.png'
import { Link } from 'react-router-dom'

type ClientsType = {
    icon: string;
    id: number;
    is_published: number;
    name: string;
};

export default function ThirdSection() {
    const [clients, setClients] = useState<ClientsType[]>([]);

    const getClients = async () => {
        try {
            const res = await axiosInstance.get('api/clients');
            const fetchedClients = res.data.data;
            setClients(adjustIconsArray(fetchedClients));
        } catch (error) {
            console.log(error);
        }
    };

    const adjustIconsArray = (icons: ClientsType[]): ClientsType[] => {
        const iconsLength = icons.length;
        if (iconsLength < 15) {
            return [...icons, ...Array(15 - iconsLength).fill(icons).flat()].slice(0, 15);
        } else {
            return icons.slice(0, 15);
        }
    };

    useEffect(() => {
        getClients();
    }, []);

    return (
        <div className='third-section-home w-full h-full flex items-center justify-start mt-32 md:mt-0 relative'>
            <div className='w-11/12 md:w-3/4 scale-90 m-auto'>
                <h2 className='text-third-section mt-20 md:mt-0 Bebas text-[#F6174E] leading-[65px] md:leading-[70px] lg:leading-[100px] text-center md:text-left w-[110%] -ms-[5%] md:ms-0 md:w-full md:whitespace-nowrap'>
                    WE HAVE A PASSION FOR CONTROLLING <br />CONSUMER'S MIND THROUGH <span className='text-[#C7DA02] block md:inline'> A DESIGN</span>
                </h2>
                <div className='flex items-center'>
                    <img className='w-[120px] md:w-[170px] lg:w-[220px] xl:w-full xl:max-w-[20%] me-4  absolute md:static -top-[50px] md:-top-[185px] left-16 md:-left-10' src={YellowSticker} alt='' />
                    <div className='p-section3 flex flex-col items-center relative w-full sm:w-full text-left md:text-center'>
                        <p className='text-[14px] sm:text-[16px] Lato font-[400] mb-6 text-[#fff] mt-6 md:mt-0'>
                            The law of the jungle is tough and says that only the strongest survive. Before you can effectively communicate your brand, you must frst build muscle by understanding your customers and competitors. We'll help you get to the heart of your business and together we'll navigate through research, strategy, design choices, and digital opportunities. A creative adventure. We like a challenge, but we don't overcomplicate it and make sure the journey is as smooth as possible.
                        </p>
                        <p className='text-[14px] sm:text-[16px] Lato font-[400] text-[#fff]'>
                            Brand builder, Design studio, Accomplice, Dance partner. What you call us doesn't matter, what matters is that we steer your brand in the right direction. The frst step is to become friends, so come over for a Coke cola and we'll be happy to tell you more. Coffee works too, of course.
                        </p>
                        <Link className='w-full flex justify-center items-center' to={'/about'}>
                            <img className='scale-75 mt-16 md:mt-0 mb-52 md:mb-0 md:absolute -bottom-32 md:scale-90' src={ReadMore} alt='' />
                        </Link>
                    </div>
                </div>
            </div>
            <div className='md:flex flex-col md:gap-[40px] scale-[0.6] md:scale-90 translate-x-[200px] md:-translate-x-4 absolute rotate-90 md:static md:rotate-0 translate-y-[420px] md:-translate-y-[10px] gap-3'>
                <div className='marquee-text-3 text-start ps-4 w-fit flex flex-col gap-[30px] h-[fit-contents]' data-motion-id="marquee1">
                    {clients.map((client, index) => (
                        <img key={index} className='-rotate-90 md:rotate-0 w-[130px]' src={`https://api-laith.alzero1store.com/${client.icon}`} alt={client.name} width={100} height={100} />
                    ))}
                </div>
                <div className='marquee-text-4 text-start ps-4 w-fit flex flex-col gap-[30px] h-[fit-contents]' data-motion-id="marquee2">
                    {clients.map((client, index) => (
                        <img key={index} className='-rotate-90 md:rotate-0 w-[130px]' src={`https://api-laith.alzero1store.com/${client.icon}`} alt={client.name} width={100} height={100} />
                    ))}
                </div>
            </div>
            <div className='hidden md:flex flex-col rotate-180 scale-90 -translate-x-8 gap-[40px]'>
                <div className='marquee-text-3 text-start ps-4 w-fit flex flex-col gap-[30px]' data-motion-id="marquee3">
                    {clients.map((client, index) => (
                        <img key={index} className="rotate-180 w-[130px]" src={`https://api-laith.alzero1store.com/${client.icon}`} alt={client.name} width={100} height={100} />
                    ))}
                </div>
                <div className='marquee-text-4 text-start ps-4 w-fit flex flex-col gap-[30px]' data-motion-id="marquee4">
                    {clients.map((client, index) => (
                        <img key={index} className="rotate-180 w-[130px]" src={`https://api-laith.alzero1store.com/${client.icon}`} alt={client.name} width={100} height={100} />
                    ))}
                </div>
            </div>
        </div>
    );
}
