import React from 'react';
import More from '../../assets/images/desktopView/Group 3.png';
import { Link } from 'react-router-dom';

interface ProjectProps {
  id: number;
  imageSrc: string;
  ProjectName: string;
  ProjectDescription: string;
  color: string;
  more: boolean;
  routingLink: string;
}

const Project: React.FC<ProjectProps> = ({
  id,
  imageSrc,
  ProjectName,
  ProjectDescription,
  color,
  more,
  routingLink,
}) => {
  return (
    <div className='project-home w-full h-full flex justify-center items-center flex-col md:flex-row mb-16 md:mb-0 -z-10'>
      <div className='w-11/12 md:w-1/2 h-fit flex flex-col items-center'>
        <div className=''>
        <h3 className='text-[16px] md:text-[25px] lg:text-[45px] 3xl:text-[60px] text-[#C7DA02] leading-4 md:leading-[50px] 3xl:leading-[70px] Lato font-[800]'>{ProjectName}</h3>
        <p className='text-[12px] md:text-[15px] lg:text-[20px] 3xl:text-[28px] text-[#F6174E] leading-5 3xl:leading-8 Lato font-[400] mb-2'>{ProjectDescription}</p>
        <div className='relative'>
          <img
            className=' max-h-[420px] w-[800px] h-[800px]'
            src={`https://api-laith.alzero1store.com/${imageSrc}`}
            alt={ProjectName}
          />
          <Link to={`projects/${id}`}>
            <div className="text-[#CCFD01] text-[14px] w-32 h-32 rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center hover:scale-110 duration-300 cursor-pointer scale-50 md:scale-100 3xl:scale-125" style={{ backgroundColor: `#${color}` }}>
              View Project
            </div>
          </Link>
        </div>
      </div>
      {more ? (
        <Link className='w-[40%] pt-10 md:w-[30%] md:pt-0 block' to={'/discover'}>
          <div className=' h-full flex items-center justify-center md:ms-24 scale-90 mt-10 md:mt-0'>
            <img className='w-full' src={More} alt='' />
          </div>
        </Link>
      ) : null}
      </div>
    </div>
  );
};

export default Project;
