import React from 'react';

import photo1 from '../../../assets/images/Projects/cedarmount/1-02.png';
import MoreWhite from '../../../assets/images/Projects/iceking/MoreWhite.png';
import Sticker from '../../../assets/images/desktopView/Frame.png';
import { Link } from 'react-router-dom';

interface ImageType {
  id: number;
  image: string;
  order: number;
}

interface ShowPicsType {
  images?: ImageType[];
  color ?: string;
}

const ShowPics: React.FC<ShowPicsType> = ({ images , color}) => {
  const defaultImages: ImageType[] = [
    { id: 1, image: photo1, order: 1 },
  ];

  const imagesArray = images || defaultImages;

  const renderImagePairs = (images: ImageType[]) => {
    return images.map((img, index) => {
      if (index % 2 === 0 && index === images.length - 1 && images.length % 2 !== 0) {
        return (
          <div key={img.id} className="flex justify-center items-center w-full">
            <img width={500} height={500} className="w-11/12 mt-5 md:w-1/2 mx-5 shadow-md" src={`https://api-laith.alzero1store.com/${img.image}`} alt="" />
          </div>
        );
      }
      if (index % 2 === 0) {
        return (
          <div key={img.id} className="flex flex-col md:flex-row justify-center items-center h-full w-11/12 m-auto pt-12 md:pt-0 md:w-screen gap-12 md:gap-0">
            <img width={500} height={500} className="w-full md:w-1/2 mx-5 shadow-md" src={`https://api-laith.alzero1store.com/${img.image}`} alt="" />
            {images[index + 1] && (
              <img width={500} height={500} className="w-full md:w-1/2 mx-5 shadow-md" src={`https://api-laith.alzero1store.com/${images[index + 1].image}`} alt="" />
            )}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className="bg-[#09a6ce] w-fit min-w-full h-full flex flex-col md:flex-row pb-12" style={{backgroundColor : `#${color}`}}>
      {renderImagePairs(imagesArray)}
      <div className="flex flex-col md:flex-row justify-center items-center h-full w-11/12 m-auto pt-12 md:pt-0 md:w-screen gap-12 md:gap-0">
        <Link to="/discover">
          <img width={500} height={500} className="w-1/2 md:w-1/2 mx-5" src={MoreWhite} alt="" />
        </Link>
        <div className="w-full flex justify-end">
          <img width={500} height={500} className="w-[130px] mx-5" src={Sticker} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ShowPics;
