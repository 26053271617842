// axiosInstance.js

import axios from "axios";


// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://api-laith.alzero1store.com/',
  headers: { 'Content-Type': 'application/json' },
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Any status code within the range of 2xx will trigger this function
    return response;
  },
  error => {
    // Any status codes outside the range of 2xx will trigger this function

    // You can customize error handling here
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      switch (error.response.status) {
        case 400:
          console.error('Bad Request:', error.response.data);
          break;
        case 401:
          console.error('Unauthorized:', "Your Session is expired !");
          break;
        case 404:
          console.error('Not Found:', error.response.data);
          break;
        case 500:
          console.error('Internal Server Error:', error.response.data);
          break;
        default:
          console.error('Error:', error.response.data);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', error.message);
    }

    // Optionally, you can return a rejected promise with a custom error message
    return Promise.reject(error);
  }
);

export default axiosInstance;
