import React, { useEffect } from 'react';

import QouteLeft from '../../assets/images/desktopView/qouteLeft.png';
import QouteRight from '../../assets/images/desktopView/qouteRight.png';
import WeAre from '../../assets/images/desktopView/We are.png';
import Sticker from '../../assets/images/desktopView/Sticker.png';

interface Props {
  rotate?: number; // Assuming rotate is of type number
  setRotate?: React.Dispatch<React.SetStateAction<number>>; // Assuming setRotate is a function that sets a number
}

const FirstSection: React.FC<Props> = ({ rotate = 0, setRotate }) => {
  useEffect(()=>{
    if(rotate <= 0 && setRotate){
      setRotate(0)
    }
  },[rotate])
  return (
    <div className='first-section-home w-full h-full relative'>
      <div className='w-11/12 h-full m-auto flex justify-end pb-10 flex-col'>
        <div className='w-2/3 h-full flex flex-col justify-center md:justify-end'>
          <div className='flex items-start mb-6'>
            <img className='qoute-start translate-y-4 -translate-x-2' src={QouteLeft} alt='' />
            <img className='we-are-hero ms-0' src={WeAre} alt='' />
          </div>
          <div className='flex items-end'>
            <h3 className='h3-hero Bebas text-[#c7da02] leading-[100px] text-left whitespace-nowrap tracking-[5px]'>A creative studio <br /> with a focus on <span className='block text-[rgb(246,23,78)]'>branding & packaging</span></h3>
            <img className='qoute-end -translate-y-16 translate-x-1' src={QouteRight} alt='' />
          </div>
        </div>
        <div className='absolute -right-10 md:right-0 -bottom-0 md:bottom-auto md:top-1/2 md:-translate-y-1/2 scale-50 md:scale-[0.6] lg:scale-75'>
          <img className='rotation-360 duration-300' style={{rotate : `${rotate >= 0 ? `${rotate}deg` : "0deg"}`}} src={Sticker} alt='' />
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
