'use client'
import AboutOverview from "../../components/AboutOverview/AboutOverview";
import DiscoverCard from "../../components/DiscoverCard/DiscoverCard";
import Nav from "../../components/Nav";
import styles from "./discover.module.css";

import emoji from "../../assets/images/Shapes/emoji.png"
import line from "../../assets/images/lineDiscover.svg"
import ContactUs from "../../components/ContactUs";
import axiosInstance from "../../api/axiosInstance";
import { useEffect, useState } from "react";

interface Service {
  id: number;
  name: string;
}

interface Project {
  id: number;
  title: string;
  presentation_image: string;
  link?: string;
  services: Service[];
}

const Discover = () => {
  const [projects, setProjects] = useState<Project[]>([]);

  const getProjects = async () => {
    try {
      const res = await axiosInstance.get('api/projects/published');
      setProjects(res.data.data);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div className={`${styles.discover} text-white flex justify-center flex-col items-center overflow-x-hidden`}>
        <Nav LogoWhite={true} humColor="White" />
        <div className="w-11/12 m-auto pt-32">
          <div className="pb-16 max-w-min mx-auto">
            <h1 className="text-7xl py-3 uppercase text-center">
              <span className={`${styles.discoverHeading} text-nowrap text-7xl md:text-[8rem] lg:text-[10rem]`}>
                discover <br />
                our work
              </span>
            </h1>
            <div className="flex items-start justify-end min-[485px]:justify-center relative mb-[50px] md:mb-[100px]">
              <img src={line} alt="" className="w-full md:me-4" />
              <img
                src={emoji}
                alt=""
                className={`${styles.emoji} object-contain`}
              />
            </div>
          </div>
          <div className={`${styles.discoverCards} w-full flex flex-wrap mb-[100px] md:hidden`}>
            {projects.map((card) => {
              const servicesDescription = card.services.map((service: Service) => service.name).join(', ');
              return (
                <DiscoverCard
                  key={card.id}
                  title={card.title}
                  img={`https://api-laith.alzero1store.com/${card.presentation_image}`}
                  description={servicesDescription}
                  link={`/projects/${card.id}`}
                />
              );
            })}
          </div>
          <div className="flex">
            <div className={`${styles.discoverCards} w-full flex-col mb-[100px] hidden md:flex`}>
              {projects.filter((_, index) => index % 2 === 0).map((card) => {
                const servicesDescription = card.services.map((service: Service) => service.name).join(', ');
                return (
                  <DiscoverCard
                    key={card.id}
                    title={card.title}
                    img={`https://api-laith.alzero1store.com/${card.presentation_image}`}
                    description={servicesDescription}
                    link={`/projects/${card.id}`}
                  />
                );
              })}
            </div>
            <div className={`${styles.discoverCards} w-full flex-col mb-[100px] hidden md:flex mt-[50px]`}>
              {projects.filter((_, index) => index % 2 === 1).map((card) => {
                const servicesDescription = card.services.map((service: Service) => service.name).join(', ');
                return (
                  <DiscoverCard
                    key={card.id}
                    title={card.title}
                    img={`https://api-laith.alzero1store.com/${card.presentation_image}`}
                    description={servicesDescription}
                    link={`/projects/${card.id}`}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <AboutOverview />
        <div className="w-full h-[200px]"></div>
        <ContactUs autoBg />
      </div>
    </div>
  );
};

export default Discover;
