'use client'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../api/axiosInstance'

import Frame from '../assets/images/Shapes/Frame (1).png'
import Lets from '../assets/images/desktopView/LetsGet.png'

interface SocialLinks {
  whatsapp: string;
  instagram: string;
  behance: string;
}

interface ContactProps {
  autoBg?: boolean;
}

const ContactUs: React.FC<ContactProps> = ({ autoBg }) => {
  const [social, setSocial] = useState<SocialLinks | null>(null);
  const [form, setForm] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const getSocialLinks = async () => {
    try {
      const res = await axiosInstance.get('api/settings');
      setSocial(res.data.data);
    } catch (e) {
      console.log("Failed to get social links");
    }
  }

  useEffect(() => {
    getSocialLinks();
  }, []);

  const validate = () => {
    const newErrors = {
      name: '',
      phone: '',
      email: '',
      message: '',
    };
    let isValid = true;

    if (!form.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    }
    if (!form.phone) {
      newErrors.phone = 'Phone is required';
      isValid = false;
    } else if (!/^\d+$/.test(form.phone)) {
      newErrors.phone = 'Phone must be a number';
      isValid = false;
    }
    if (!form.email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(form.email)) {
      newErrors.email = 'Email must be valid';
      isValid = false;
    }
    if (!form.message) {
      newErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validate()) {
      try {
        const res = await axiosInstance.post('api/contacts/send', form);
        alert('Message sent successfully');
      } catch (error) {
        alert('Failed to send message');
      }
    }
  }

  return (
    <section className={`contact-us-section w-full h-[115vh] md:h-screen pt-14 md:pt-7 pb-32 md:pb-16 relative`} style={{ backgroundImage: `${autoBg ? "none" : ""}` }}>
      <div className='w-11/12 h-full flex flex-col md:flex-row m-auto'>
        <h3 className='w-full md:w-1/3 h-full md:-rotate-90 flex justify-center items-center text-[66px] md:text-[170px] 3xl:text-[230px] 4xl:text-[267px] text-[#F6174E] Bebas mb-32 md:mb-0'>Contact</h3>
        <div className='w-11/12 m-auto md:w-2/3 h-full flex flex-col justify-center items-start md:translate-y-5 md:translate-x-12 relative'>
          <img className='absolute -top-[140px] md:-top-24 -right-[30px] md:right-5 w-[150px] md:w-[350px] lg:w-[400px] md:scale-[0.65] 3xl:scale-100 3xl:top-0' src={Lets} alt='' />
          <img className='absolute top-1/2 translate-y-7 md:-translate-y-1/2 left-1/2 -translate-x-[75px] md:-translate-x-16 scale-[0.65] md:scale-100' src={Frame} alt='' />
          <form onSubmit={handleSubmit} className='w-full md:w-2/3'>
            <input
              className={`w-full opacity-70 mt-10 3xl:text-[23px] 3xl:pb-4 focus:opacity-100 duration-300 bg-transparent border-b border-white ps-1 pb-2 focus:outline-0 text-white placeholder:text-white ${errors.name && 'border-red-500'}`}
              placeholder='Name'
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            <input
              className={`w-full opacity-70 mt-10 3xl:text-[23px] 3xl:pb-4 focus:opacity-100 duration-300 bg-transparent border-b border-white ps-1 pb-2 focus:outline-0 text-white placeholder:text-white ${errors.phone && 'border-red-500'}`}
              placeholder='Phone'
              type="text"
              name="phone"
              value={form.phone}
              onChange={handleChange}
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            <input
              className={`w-full opacity-70 mt-10 3xl:text-[23px] 3xl:pb-4 focus:opacity-100 duration-300 bg-transparent border-b border-white ps-1 pb-2 focus:outline-0 text-white placeholder:text-white ${errors.email && 'border-red-500'}`}
              placeholder='Email'
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            <input
              className={`w-full opacity-70 mt-10 3xl:text-[23px] 3xl:pb-4 focus:opacity-100 duration-300 bg-transparent border-b border-white ps-1 pb-2 focus:outline-0 text-white placeholder:text-white ${errors.message && 'border-red-500'}`}
              placeholder='Message'
              type="text"
              name="message"
              value={form.message}
              onChange={handleChange}
            />
            {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
            <button type="submit" className='text-[#141414] mt-10 uppercase text-[22px] md:text-[29px] 3xl:text-[32px] Lato font-[600] bg-[#C7DA02] w-[160px] md:w-[200px] h-[55px] md:h-[65px] 3xl:w-[220px] 3xl:h-[75px] flex justify-center items-center scale-75 -translate-x-6 rounded-[6px]'>Send</button>
          </form>
        </div>
      </div>
      {social && (
        <div className='absolute right-1/2 translate-x-1/2 md:right-12 md:translate-x-0 scale-75 md:scale-90 lg:scale-100 bottom-6 md:bottom-10 flex gap-12 3xl:bottom-24 3xl:right-24 3xl:scale-[1.2]'>
          <a href={social.whatsapp}><span className='text-[#fff] text-[28px] Lato font-[400] underline cursor-pointer'>whatsapp</span></a>
          <a href={social.instagram}><span className='text-[#fff] text-[28px] Lato font-[400] underline cursor-pointer'>instagram</span></a>
          <a href={social.behance}><span className='text-[#fff] text-[28px] Lato font-[400] underline cursor-pointer'>Behance</span></a>
        </div>
      )}
    </section>
  )
}

export default ContactUs;
