'use client'
import React, { useEffect, useState } from 'react'

import whiteLogo from '../assets/images/Logos/whiteLogo.png'
import blackLogo from '../assets/images/Logos/blackLogo.png'
import underLine from '../assets/images/Shapes/underline.png'
import { useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance'
import { Link } from 'react-router-dom'

interface SocialLinks {
    whatsapp: string;
    instagram: string;
    behance: string;
}

interface NavProps {
    humColor ?: string ;
    LogoWhite ?: boolean ;
}

const Nav : React.FC<NavProps> = ({ humColor , LogoWhite }) => {
    const [showNav , setShowNav] = useState<boolean>(false);
    const location  = useLocation();
    const router = location.pathname;
    const [social, setSocial] = useState<SocialLinks | null>(null);
    const getSocialLinks = async () => {
        try {
          const res = await axiosInstance.get('api/settings');
          setSocial(res.data.data);
        } catch (e) {
          console.log("Failed to get social links");
        }
      }
    
      useEffect(() => {
        getSocialLinks();
      }, []);
    return (
        <nav className='w-full'>
            <div className=''>
                <div className='w-11/12 fixed left-1/2 -translate-x-1/2 z-[41]'>
                    <div className='flex justify-between h-24 items-center w-full 3xl:h-28'>
                        <Link to={"/"}>
                        <div>
                            <img  className={`w-200 h-200 absolute w-[120px] logo-nav ${showNav || !LogoWhite ? "opacity-0" : "opacity-100"} duration-300`} src={whiteLogo} alt='' />
                            <img  className={`w-200 h-200 ${showNav || !LogoWhite ? "opacity-100" : "opacity-0"} w-[120px] logo-nav duration-300`} src={blackLogo} alt='' />
                        </div>
                        </Link>
                        <div className='flex flex-col h-6 justify-between cursor-pointer 3xl:scale-125' onClick={() => {setShowNav(!showNav)}}>
                            <svg className={`nav-icn${!showNav ? "" : " rotate-45 translate-y-3"} duration-300`} width="34" height="4" viewBox="0 0 34 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.636578 3.69736C8.74423 3.939 16.8519 3.939 24.9595 3.73188C27.2911 3.66284 29.5698 3.5938 31.9014 3.49023C32.1133 3.49023 32.3253 3.21407 32.3783 3.14503C32.6432 2.83435 32.8552 2.48915 33.0142 2.17847C33.0672 2.07491 33.6501 0.659582 33.3321 0.694103C25.2245 1.0393 17.1168 1.14286 9.00918 1.0393C6.67757 1.00478 4.34596 0.970264 2.06734 0.901224C1.85537 0.901224 1.64341 1.17738 1.59042 1.24643C1.32546 1.55711 1.1135 1.90231 0.954525 2.21299C0.901534 2.31655 0.26564 3.69736 0.636578 3.69736Z" fill={showNav ? "black":`${humColor ? humColor : "white"} ` }/>
                            </svg>
                            <svg className={`${!showNav ?"" : "opacity-0"} duration-300`} width="34" height="4" viewBox="0 0 34 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.636578 3.69736C8.74423 3.939 16.8519 3.939 24.9595 3.73188C27.2911 3.66284 29.5698 3.5938 31.9014 3.49023C32.1133 3.49023 32.3253 3.21407 32.3783 3.14503C32.6432 2.83435 32.8552 2.48915 33.0142 2.17847C33.0672 2.07491 33.6501 0.659582 33.3321 0.694103C25.2245 1.0393 17.1168 1.14286 9.00918 1.0393C6.67757 1.00478 4.34596 0.970264 2.06734 0.901224C1.85537 0.901224 1.64341 1.17738 1.59042 1.24643C1.32546 1.55711 1.1135 1.90231 0.954525 2.21299C0.901534 2.31655 0.26564 3.69736 0.636578 3.69736Z" fill={showNav ? "black":`${humColor ? humColor : "white"} ` }/>
                            </svg>
                            <svg className={`${!showNav ? "" : " -rotate-[225deg] -translate-y-2"} duration-300`} width="34" height="4" viewBox="0 0 34 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.636578 3.69736C8.74423 3.939 16.8519 3.939 24.9595 3.73188C27.2911 3.66284 29.5698 3.5938 31.9014 3.49023C32.1133 3.49023 32.3253 3.21407 32.3783 3.14503C32.6432 2.83435 32.8552 2.48915 33.0142 2.17847C33.0672 2.07491 33.6501 0.659582 33.3321 0.694103C25.2245 1.0393 17.1168 1.14286 9.00918 1.0393C6.67757 1.00478 4.34596 0.970264 2.06734 0.901224C1.85537 0.901224 1.64341 1.17738 1.59042 1.24643C1.32546 1.55711 1.1135 1.90231 0.954525 2.21299C0.901534 2.31655 0.26564 3.69736 0.636578 3.69736Z" fill={showNav ? "black":`${humColor ? humColor : "white"} ` }/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`menu-nav w-full h-screen fixed z-10 flex justify-end ${showNav ? "translate-y-0" : "-translate-y-full"} duration-300 ease-in z-40`}>
                <div className='w-full md:w-1/2 h-full flex flex-col justify-end items-center md:items-start scale-50 md:scale-[0.55] lg:scale-[0.6] md:-translate-x-32 translate-y-7 md:translate-y-20 md:gap-4 relative z-50'>
                    <Link className={`uppercase flex justify-center md:justify-start w-fit md:w-[70%] relative text-[145px] 3xl:text-[186px] 4xl:text-[200px] 3xl:scale-100 Bebas text-[#131314] whitespace-nowrap ${router === '/' ? 'active-nav-link' : ''}`} to={'/'} onClick={() => {setShowNav(!showNav)}}>Home <img className='-mt-10 hidden absolute -bottom-2 w-full' src={underLine} alt='' /></Link>
                    <Link className={`uppercase flex justify-center md:justify-start w-fit md:w-[70%] relative text-[145px] 3xl:text-[186px] 4xl:text-[200px] 3xl:scale-100 Bebas text-[#131314] whitespace-nowrap ${router === '/discover' ? 'active-nav-link' : '' || router === '/discover/' ? 'active-nav-link' : ''}`} to={'/discover'} onClick={() => {setShowNav(!showNav)}}>Work <img className='-mt-10 hidden absolute -bottom-2 w-full' src={underLine} alt='' /></Link>
                    <Link className={`uppercase flex justify-center md:justify-start w-fit md:w-[70%] relative text-[145px] 3xl:text-[186px] 4xl:text-[200px] 3xl:scale-100 Bebas text-[#131314] whitespace-nowrap ${router === '/about' ? 'active-nav-link' : '' || router === '/about/' ? 'active-nav-link' : ''}`} to={'/about'} onClick={() => {setShowNav(!showNav)}}>About Us <img className='-mt-10 hidden absolute -bottom-2 w-full' src={underLine} alt='' /></Link>
                    <Link className={`uppercase flex justify-center md:justify-start w-fit md:w-[70%] relative text-[145px] 3xl:text-[186px] 4xl:text-[200px] 3xl:scale-100 Bebas text-[#131314] whitespace-nowrap ${router === '/contact-us' ? 'active-nav-link' : '' || router === '/contact-us/' ? 'active-nav-link' : ''}`} to={'/contact-us'} onClick={() => {setShowNav(!showNav)}}>Contact Us <img className='-mt-10 hidden absolute -bottom-2 w-full' src={underLine} alt='' /></Link>
                </div>
                <div className='absolute bottom-14 left-1/2 -translate-x-1/2 md:translate-x-0 md:left-12 3xl:scale-125 3xl:left-32'>
                    <a href={social?.whatsapp}><span className='text-[25px] underline Lato font-[400] text-[#131314] me-7'>Whatsapp</span></a>
                    <a href={social?.instagram}><span className='text-[25px] underline Lato font-[400] text-[#131314] me-7'>Instagram</span></a>
                    <a href={social?.behance}><span className='text-[25px] underline Lato font-[400] text-[#131314]'>Behance</span></a>
                </div>
            </div>
        </nav>
    )
}

export default Nav;
